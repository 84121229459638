import TotalRolesTable from "@presentation/components/roles/TotalRolesTable";
import { RoleHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getRoles } from "@adapters/store/roles/slice";
import { useEffect, useState } from "react";
import { listRoles } from "@adapters/store/roles/thunk";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { useTranslation } from "react-i18next";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";
import { Role } from "../../domain/entities/Roles";
import TextField from "@presentation/components/inputs/TextField";
import LoopIcon from "@presentation/components/Icons/LoopIcon";
import useStoredPagination from "@presentation/utils/StoredPagination";

const Roles = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const headCells: RoleHeadCell[] = [
    {
      id: "roleName",
      numeric: false,
      disablePadding: true,
      label: t("datasets_roles.headers.role"),
      enableSorting: true,
    },
    {
      id: "datasetName",
      numeric: false,
      disablePadding: false,
      label: t("datasets_roles.headers.dataset"),
      enableSorting: true,
    },
    {
      id: "scopesLength",
      numeric: true,
      disablePadding: false,
      label: t("datasets_roles.headers.scopes"),
      enableSorting: true,
    },
    {
      id: "filtersLength",
      numeric: true,
      disablePadding: false,
      label: t("datasets_roles.headers.values"),
      enableSorting: false,
    },
  ];

  const dispatch = useAppDispatch();

  const rows = useAppSelector(getRoles);

  useEffect(() => {
    dispatch(listRoles());
  }, [dispatch]);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  const [rolesList, setRolesList] = useState<Role[]>(rows);
  const [storedPagination, setStoredPagination] = useStoredPagination("roles", {
    page: 0,
    order: "asc",
    orderBy: "roleName",
    search: "",
  });

  useEffect(() => {
    const search = storedPagination.search as string;
    if (search?.length >= 1) {
      setRolesList(
        rows.filter(
          (role: Role) =>
            role.roleName?.toLowerCase().includes(search.toLowerCase()) ||
            role.datasetName?.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setRolesList(rows);
    }
  }, [rows, storedPagination.search]);

  const handleSearch = (value: string): void => {
    setStoredPagination({ ...storedPagination, search: value, page: 0 });
  };

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px", width: "100%" }}>
        <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
          {t("datasets_roles.title", { count: rows.length })}
        </Typography>
        <TotalTooltip title={t("datasets_roles.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
        <Box sx={{ marginLeft: "auto", marginRight: "8px", display: "flex" }}>
          <TextField
            label={t("search_bar.search")}
            value={storedPagination.search}
            textPlace="left"
            onChange={handleSearch}
            sx={{ mr: 2 }}
            inputProps={{
              endAdornment: <LoopIcon sx={{ color: theme.custom.colors.brand.primary[500] }} />,
            }}
          />
        </Box>
      </Box>

      <TotalRolesTable headCells={headCells} rows={rolesList}></TotalRolesTable>
    </Grid>
  );
};

export default Roles;
