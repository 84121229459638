import { useEffect, useState } from "react";
import TotalScopesTable from "@presentation/components/scopes/TotalScopesTable";
import { ScopeHeadCell } from "@domain/Interfaces/HeadCell.d";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@core/store/hook";
import { getScopes } from "@adapters/store/scopes/slice";
import { listScopes } from "@adapters/store/scopes/thunk";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TotalTooltip from "@presentation/components/TotalTooltip";
import { useTranslation } from "react-i18next";
import TooltipIconTrigger from "@presentation/components/TooltipIconTrigger";
import { Scope } from "../../domain/entities/Scopes";
import TextField from "@presentation/components/inputs/TextField";
import LoopIcon from "@presentation/components/Icons/LoopIcon";
import useStoredPagination from "@presentation/utils/StoredPagination";

const Scopes = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const headCells: ScopeHeadCell[] = [
    {
      id: "scopeName",
      numeric: false,
      disablePadding: true,
      label: t("manage_scopes.headers.scope_name"),
      enableSorting: true,
    },
    {
      id: "datasetLength",
      numeric: false,
      disablePadding: false,
      label: t("manage_scopes.headers.dataset"),
      enableSorting: true,
    },
    {
      id: "totalRoles",
      numeric: true,
      disablePadding: false,
      label: t("manage_scopes.headers.roles"),
      enableSorting: false,
    },
  ];

  const dispatch = useAppDispatch();
  const rows = useAppSelector(getScopes);

  useEffect(() => {
    dispatch(listScopes());
  }, [dispatch]);

  const [infoTooltipState, setInfoTooltipState] = useState<boolean>(false);

  const [scopesList, setScopesList] = useState<Scope[]>(rows);
  const [storedPagination, setStoredPagination] = useStoredPagination("scopes", {
    page: 0,
    order: "asc",
    orderBy: "scopeName",
    search: "",
  });

  useEffect(() => {
    const search = storedPagination.search as string;
    if (search?.length >= 1) {
      setScopesList(rows.filter((scope: Scope) => scope.scopeName?.toLowerCase().includes(search.toLowerCase())));
    } else {
      setScopesList(rows);
    }
  }, [rows, storedPagination.search]);

  const handleSearch = (value: string): void => {
    setStoredPagination({ ...storedPagination, search: value, page: 0 });
  };

  return (
    <Grid container direction="column" alignItems="start">
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px", width: "100%" }}>
        <Typography sx={{ my: "17px", ml: "22px", color: "#374649" }} variant="h2">
          {t("manage_scopes.title", { count: rows.length })}
        </Typography>
        <TotalTooltip title={t("manage_scopes.description")} setOpenState={setInfoTooltipState}>
          <Box>
            <TooltipIconTrigger tooltipState={infoTooltipState}>
              <InfoOutlinedIcon sx={{ color: "red", height: "20px", cursor: "help" }} />
            </TooltipIconTrigger>
          </Box>
        </TotalTooltip>
        <Box sx={{ marginLeft: "auto", marginRight: "8px", display: "flex" }}>
          <TextField
            label={t("search_bar.search")}
            value={storedPagination.search}
            textPlace="left"
            onChange={handleSearch}
            sx={{ mr: 2 }}
            inputProps={{
              endAdornment: <LoopIcon sx={{ color: theme.custom.colors.brand.primary[500] }} />,
            }}
          />
        </Box>
      </Box>
      <TotalScopesTable headCells={headCells} rows={scopesList} storageKey={"scopes-list"}></TotalScopesTable>
    </Grid>
  );
};

export default Scopes;
